// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-layouts-js": () => import("./../../../src/pages/layouts.js" /* webpackChunkName: "component---src-pages-layouts-js" */),
  "component---src-templates-page-page-template-jsx": () => import("./../../../src/templates/page/page.template.jsx" /* webpackChunkName: "component---src-templates-page-page-template-jsx" */),
  "component---src-templates-post-post-template-jsx": () => import("./../../../src/templates/post/post.template.jsx" /* webpackChunkName: "component---src-templates-post-post-template-jsx" */)
}

